import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    constructor( ) { }

    /**
     * Setear algo en el localStorage, puede ser un json
     */
    setObject = (key) => (value) => {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Obtener algo del localStorage
     */
    getObject = (key) => {
        var value = localStorage.getItem(key);
        return value && JSON.parse(value);
    }

    /**
     * Limpia el local storage y el session (menos las notis guardadas)
     */
    clearLocalAndSessionStorage = () => {
        sessionStorage.clear();

        localStorage.removeItem('accesoActivo');
        localStorage.removeItem('leftMenu');

    }

    /**
     * Retorna el usuario logueado activo
     */
    getUsuarioActivo = () =>
        this.getObject('accesoActivo') && this.getObject('accesoActivo').usuario ?
            this.getObject('accesoActivo').usuario : null

    /**
     * Setear algo en el sessionStorage, puede ser un json
     */
    setSessionObject = (key) => (value) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Obtener algo del sessionStorage
     */
    getSessionObject = (key) => {
        var value = sessionStorage.getItem(key);
        return value && JSON.parse(value);
    }


}
