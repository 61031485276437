import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Notificacion } from "../models/notificacion";
import { CartaPorte } from "../models/cartaPorte";

@Injectable()
export class NotificacionesService {
    private notificacionesOnline = new BehaviorSubject<Notificacion[]>([]);

    private cartasParaAccionar = new BehaviorSubject<CartaPorte[]>([]);

    constructor( ) { }

    addNotificaciones = (newNotis: Notificacion[]) => {
        const notis = this.notificacionesOnline.value.concat(newNotis);
        this.notificacionesOnline.next(notis);
    }
    getNotificacionesSubject = () => this.notificacionesOnline;
    cleanNotificaciones = () => this.notificacionesOnline.next([]);

    // Cartas para accionar compartidas
    addCartasParaAccionar = (cartas: CartaPorte[]) => {
        // const cartasConcat = this.cartasParaAccionar.value.concat(cartas);
        this.cartasParaAccionar.next(cartas);
    }
    getCartasParaAccionarSubject = () => this.cartasParaAccionar;

    /**
     * Borra todas las notificaciones correspondientes a las cartas dadas
     */
    removeFromNotificaciones = (cartasSeleccionadas) => {
        // Agarro todas las que NO sean 'cartasSeleccionadas'
        const notis = this.notificacionesOnline.value
            .filter(
                (noti: Notificacion) => cartasSeleccionadas
                    .find(
                        (carta: CartaPorte) => noti.carta.porteNro !== carta.porteNro
                    )
            )

        this.notificacionesOnline.next(notis);
    }

    // Si las nuevas con problemas CONTIENEN a todas las notifs. Las uqe no las contengan, las borro
    removerNotifObsoletas = (conProblemasNuevos: CartaPorte[]) => {
        const notifsActuales: Notificacion[] = this.notificacionesOnline.value;

        const noObsoletas = notifsActuales
            .filter(
                noti => conProblemasNuevos
                    .some(
                        c =>    c.porteNro === noti.carta.porteNro &&
                                c.porteVagon === noti.carta.porteVagon &&
                                c.porteCodPuerto === noti.carta.porteCodPuerto &&
                                c.entregador.cuit === noti.carta.entregador.cuit
                    )
            )



        this.notificacionesOnline.next(noObsoletas)
    }
}
