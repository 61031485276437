import { catchError, tap} from 'rxjs/internal/operators';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { of, zip, Observable} from 'rxjs';
import { Puerto } from '../../../models/puerto';
import { AuthService } from '../../../services/authService';
import { TipoAccion } from 'src/app/models/tipoAccion';
import { CartaPorte } from 'src/app/models/cartaPorte';
import { UtilsService } from 'src/app/services/utilsService';

@Component({
    selector: 'accion-dialog',
    templateUrl: 'accionDialog.html',
    styleUrls: ['./accionDialog.scss']
})
export class AccionDialog {

    spinner = false;
    continuar = false;
    cartasSeleccionadas: CartaPorte[] = [];

    displayedColumns  = [
        'nroCP',
        'situacion',
        'turno',
        'titular',
        'corredor',
        'especie',
        'destino',
        'procedencia'
    ];

    listasDesplegables: {
        situaciones: TipoAccion[],
        destinos: Observable<Puerto[]>
    } = { situaciones: [], destinos: null };

    destinoEnComentarios: boolean = false;

    // Datos de la nueva accion
    datosAccion: {
        puertoNuevo: Puerto,
        comentario: string,
        tipoAccion: TipoAccion
    } = { puertoNuevo: new Puerto(), comentario: '', tipoAccion: new TipoAccion() }

    constructor(
        public dialogRef: MatDialogRef<AccionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authService: AuthService,
        private utilsService: UtilsService
    ) {
        this.cartasSeleccionadas = data.cartasSeleccionadas;
        this.listasDesplegables = this.getListasDesplegables();
    }

    getListasDesplegables = () => {
        return {
            // situaciones: ['Autorizar', 'Desviar', 'Solicitar Revision'],
            situaciones: [
                new TipoAccion({tipo: 1, descripcion: 'Autorizar'}),
                new TipoAccion({tipo: 2, descripcion: 'Desviar'}),
                new TipoAccion({tipo: 3, descripcion: 'Solicitar Revision'}),
                new TipoAccion({tipo: 4, descripcion: 'Recibidor Oficial'})
            ],
            destinos: this.authService.getDestinos()
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onClickAceptar = () => {
        this.spinner = true;
    
        // Verificar si hay cartas seleccionadas
        if (this.cartasSeleccionadas.length === 0) {
            this.spinner = false;
            this.utilsService.openDefaultDialog('Error')('No hay cartas seleccionadas.');
            return; // Salir si no hay cartas
        }
    
        // Mapeo todas las cartas seleccionadas como observables de las requests a hacer
        const accionesObservables = this.cartasSeleccionadas.map(carta => 
            this.authService.setAccion(carta)(this.datosAccion).pipe(
                tap(response => {
                    console.log('Respuesta de la API para carta:', carta, response); // Log de respuesta
                }),
                catchError(error => {
                    console.error('Error en la llamada a la API para carta:', carta, error);
                    return of(`Error para carta ${carta.porteNro}`); // Retornar mensaje de error
                })
            )
        );
    
        // Ejecuto todas las requests en paralelo
        zip(...accionesObservables).pipe(
            catchError(err => {
                console.error('Error en las solicitudes:', err);
                return of(err); // Maneja el error globalmente
            })
        ).subscribe(results => {
            this.spinner = false; // Detener el spinner
            console.log('Resultados de las solicitudes:', results); // Ver qué resultados recibes
    
            // Verificar si hay errores en los resultados
            const errores = results.filter(result => result.includes("Error"));
            if (errores.length > 0) {
                this.utilsService.openDefaultDialog('Error')('No se pudo realizar la acción para algunas cartas.');
            } else {
                // Validar si todas las respuestas son éxitos
                if (results.every(result => result.trim() === "Acción procesada con éxito.")) {
                    this.utilsService.openDefaultDialog('Éxito')('La operación se realizó con éxito.'); // Mostrar mensaje de éxito
                    this.dialogRef.close(this.datosAccion);
                } else {
                    this.utilsService.openDefaultDialog('Error')('No se pudo realizar la acción requerida.');
                }
            }
        });
    }
    
    
    

}
