import { Injectable } from "@angular/core";
import { AuthService } from "./authService";
import { LocalStorageService } from "./localStorageService";
import { UtilsService } from "./utilsService";

import * as moment from 'moment';
import { BehaviorSubject } from "rxjs";
import { Noticia } from "../models/noticia";

@Injectable()
export class NoticiasService {
    momento = moment;

    private noticiasOnline = new BehaviorSubject<Noticia[]>([]);

    constructor(
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private utilsService: UtilsService
    ) {
        // Configuro moment
        this.momento.locale('es');
    }

    /**
     * Agregar noticias a las noticias cacheadas en el localStorage
     */
    actualizarNoticias = (newNotis) => {
        // Limite de noticias mostradas en notificaciones
        const MAX_SIZE = 10;

        // Agarro las noticias cacheadas y les concateno las nuevas
        const notisCacheadas: any[] = this.localStorageService.getObject('notisCacheadas') ?
            this.localStorageService.getObject('notisCacheadas').concat(newNotis) : newNotis;

        // Las trunco a solo las últimas MAX_SIZE (las ultimas 10)
        const notisTruncadas: any[] = notisCacheadas
            .sort(
                (a: Noticia, b: Noticia) => b.id - a.id
            )
            .slice(0, MAX_SIZE);

        // Checkeo si se borró alguna de la base
        this.checkIfNotisAreValid(notisTruncadas).then(noticiasValidadas => {
            // Seteo en el storage
            this.localStorageService.setObject('notisCacheadas')(noticiasValidadas);

            // Actualizo al observable para que escuche Home
            this.noticiasOnline.next(noticiasValidadas);
        })

    }

    getNoticiasSubject = () => this.noticiasOnline;


    /**
     * Obtener noticias nuevas
     */
    getNews = () => {
        // Agarro la ultima fecha de busqueda (es un string en formato yyyyMMddHHmmss)
        const ultimaFecha = this.localStorageService.getObject('ultimaFecha');
        // Si no hay ultima fecha guardada, seteo la de ayer
        const fechaAyer = moment(this.utilsService.getYesterday()).format('YYYY-MM-DDTHH:mm:ss');
        if (!ultimaFecha) {
            this.localStorageService.setObject('ultimaFecha')(fechaAyer);
        }
        return this.authService.getHistorialNoticias(
            ultimaFecha ? ultimaFecha : fechaAyer
        )
    }


    /**
     * Actualiza todas las notis
     */
    actualizar = () => {
        // Obtengo noticias nuevas
        this.getNews().subscribe(newNotis => {
            if (newNotis) {
                // Las agrego al localStorage y al observable
                this.actualizarNoticias(newNotis);
                // Actualizo ultima fecha (si hay nuevas notis)
                if (newNotis.length > 0) {
                    this.findAndSetUltimaFecha(newNotis);
                }
            }
        });
    }

    /** Esto lo disparo cada vez que revisa por nuevas
     * Checkea que las noticias sean válidas y no estén obsoletas.
     * Para eso simplemente consulta la snoticias de ayer y hoy, y checkea que las notis del parámetro estén todas en las de ayer y hoy de la base
     * Las que no están, simplemente las borro
     */
    checkIfNotisAreValid = (notis: Noticia[]) => {
        // Consulta las noticias de ayer y hoy (formateandolá)
        const ayerYHoy = moment()
            .subtract(2, 'days')
            .toISOString(); // Esto incluye la zona horaria

        return this.authService.getHistorialNoticias(ayerYHoy)
            .toPromise()
            .then((notisAyerHoy: Noticia[])=> {
                // Voy a sacar todas las noticias que NO estén entre ayer y hoy
                const noticiasValidadas = notis
                    .filter(
                        nt => notisAyerHoy.some(
                            notiAyerHoy => nt.id === notiAyerHoy.id
                        )
                    );

                return noticiasValidadas;
            });

    }

    /**
     * Recibe las nuevas noticias, busca la última fecha y la setea
     */
    findAndSetUltimaFecha = (newsNoticias: Noticia[]) => {
        // Mapeo las fechas en un array
        const fechas = newsNoticias.map(noti => noti.fechaHora);

        // Busco la última
        const ultimaFecha = new Date(
            Math.max.apply(
                null,
                fechas.map((e) =>new Date(e))
            )
        );

        // La formateo
        const ultFechaFormat = moment(ultimaFecha).format('YYYY-MM-DDTHH:mm:ss');

        // La guardo en el local storage
        this.localStorageService.setObject('ultimaFecha')(ultFechaFormat);
    }

    /**
     * Anima las noticias de arriba
     */
    initAnimation = () => {
        const noticias: any = document.getElementsByClassName('noticias-slider') && document.getElementsByClassName('noticias-slider')[0] ?
            document.getElementsByClassName('noticias-slider')[0] : null;

        if (noticias) {

            setInterval(() => {
                // Left de el slider noticias
                const leftNoticias = Number(
                    noticias.style.left ? noticias.style.left.substring(0, noticias.style.left.indexOf('p')) : null
                );

                // Width del slider noticias
                const widthNoticias = Number(noticias.offsetWidth);

                const diferencia = Number(
                    Number(widthNoticias) + Number(leftNoticias)
                );
                // Si se movió mas que su tamaño, lo pongo a la derecha
                if (diferencia <= 0 && widthNoticias !== 0) {
                    // Lo pongo a la derecha
                    noticias.style.left = `${Number(widthNoticias) - 20}px`;

                } else {
                    // Hago el movimiento
                    noticias.style.left = `${leftNoticias - 1}px`;

                }

            }, 10)
        }
    }


    /**
     * Reemplaza todas en storage
     */
    setNoticiasStorage = (notis) => {
        // Seteo en el storage
        this.localStorageService.setObject('notisCacheadas')(notis);
    }

}
