import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LocalStorageService } from "src/app/services/localStorageService";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private localStorageService: LocalStorageService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const accesoActivo = JSON.parse(localStorage.getItem("accesoActivo"));
        return accesoActivo ? next.handle(
                  req.clone({
                      headers: req.headers.set(
                          "Authorization",
                          `Bearer ${accesoActivo.token}`
                      ),
                  })
              )
            : next.handle(req);
    }
}
