export class Analisis {
    anl_porc_analisis: number;
    anl_porc_merma: number;
    anl_kilos_merma: number;
    anl_cantidad: number;
    anl_rubro: number;
    rbr_abrev: string;
    rbr_descripcion: string

    constructor(Analisis?: {
        anl_porc_analisis: number;
        anl_porc_merma: number;
        anl_kilos_merma: number;
        anl_cantidad: number;
        anl_rubro: number;
        rbr_abrev: string;
        rbr_descripcion: string
    }) {
        if (Analisis) {
            this.anl_porc_analisis = Analisis.anl_porc_analisis;
            this.anl_porc_merma = Analisis.anl_porc_merma;
            this.anl_kilos_merma = Analisis.anl_kilos_merma;
            this.anl_cantidad = Analisis.anl_cantidad;
            this.anl_rubro = Analisis.anl_rubro;
            this.rbr_abrev = Analisis.rbr_abrev;
            this.rbr_descripcion = Analisis.rbr_descripcion
        } else {
            this.anl_porc_analisis = null;
            this.anl_porc_merma = null;
            this.anl_kilos_merma = null;
            this.anl_cantidad = null;
            this.anl_rubro = null;
            this.rbr_abrev = null;
            this.rbr_descripcion = null
        }
    }

}
