import { Analisis } from "src/app/models/analisis";

export class CartaPorteDetalle {

    portePrefijo: string;
    porteNro: string;
    porteCuitEntregador: number;
    porteCodMerca: number;
    porteCuitCorredor: number;
    porteCuitDestinatario: number;
    porteCodProcedencia: number;
    porteKgsProcede: number;
    porteContrato: string;
    porteFechaDes: Date;
    porteCuitTransp: number;
    porteTurno: number;
    porteEstado: number;
    porteCuitRemitente: number;
    porteCuitCyo: number;
    porteCuitCyo2: number;
    porteFechaArr: Date;
    porteBruto: number;
    porteKgsTara: number;
    porteKgsNeto: number;
    porteMedio: number;
    porteCodPuerto: number;
    portePatenteCamion: string;
    porteHoraArr: Date;
    porteDescarObs: string;
    porteCalidad: number;
    porteVagon: number;
    porteNroPlanta: number;
    porteTranspRazon: string;
    porteObservacion: string;
    porteCodTransp: number;
    porteKilosMerma: number;
    portePreDesvio: number;
    porteNroDesvio: number;
    porteRecibo: number;
    porteHoraSal: Date;
    porteFechaCp: Date;
    porteTipo: number;
    porteNroPlaCyo1: number;
    porteNroPlaCyo2: number;
    porteNroAnt: number;
    portePreAnt: number;
    porteVagonAnt: number;
    porteNetoAnt: number;
    porteCamara: string;
    porteSolicAnalisis: number;
    porteDestinExis: number;
    porteCc: number;
    porteAutorizado: number;
    porteCosecha: string;
    porteImpExp: number;
    porteImpEnt: number;
    porteTanda: number;
    porteFirmaTransp: number;
    porteCtg: number;
    porteTitular: string;
    porteInterm: string;
    porteRemi: string;
    destin: string;
    corre: string;
    cereal: string;
    proce: string;
    entreCp: string;
    estadoPosi: number;
    entre: string;
    obsEstado: string;
    codMerca: number;
    descripcionMercaderia: string;
    tipoMercaderia: number;
    cosecha: string;
    tarifaXtonelada: number;
    totalPlanta: number;
    turnoCalado: number;
    asigTurno: number;
    hd: number;
    estadoPosiDes: string;
    ptoCodInterno: number;
    ptoCuit: number;
    ptoPostalOnnca: number;
    ptoRazon: string;
    ptoDomicilio: string;
    ptoDomiNro: number;
    toTelefono: string;
    ptoFax: string;
    ptoMail: string;
    ptoTurno: string;
    ptoAdmin: number;
    ptoPplantaAct: number;
    ptoLocalOncca: number;
    ptoControlTurno: string;
    ptoPlanta: number;
    pAdicCuitChofer: number;
    pAdicRazonChofer: string;
    pAdicFechaVenc: Date;
    pAdicBrutoProc: number;
    pAdicTaraProc: number;
    pAdicPatenteAcopl: string;
    pAdicPNroCac: number;
    cldNomenclatura: string;
    destinatariodeno: string;
    cp: string;
    analiDet: string;
    obsAnalisis: string;
    analisis: Analisis[];
    autPuerto: string;
    nomCorre2: string;
    corre2: number;
    ruta: string;
    obsPuerto: string;
    actComentario: string;
    rte1Denominacion: string; // Hace referencia al remitente comercial 2 de la DB
    rte1Cuit: number;
    rte2Denominacion: string; // Hace referencia al remitente comercial 3 de la DB
    rte2Cuit: number;
    pAdicNroCupo: number;
    pAdicNroOp: string;
    sucursal: number;
    nroOrden: number;
    tipoDocumento: string;

    constructor(cp: any) {
        if (cp) {
            this.portePrefijo = cp.portePrefijo;
            this.porteNro = cp.porteNro;
            this.porteCuitEntregador = cp.porteCuitEntregador;
            this.porteCodMerca = cp.porteCodMerca;
            this.porteCuitCorredor = cp.porteCuitCorredor;
            this.porteCuitDestinatario = cp.porteCuitDestinatario;
            this.porteCodProcedencia = cp.porteCodProcedencia;
            this.porteKgsProcede = cp.porteKgsProcede;
            this.porteContrato = cp.porteContrato;
            this.porteFechaDes = cp.porteFechaDes;
            this.porteCuitTransp = cp.porteCuitTransp;
            this.porteTurno = cp.porteTurno;
            this.porteEstado = cp.porteEstado;
            this.porteCuitRemitente = cp.porteCuitRemitente;
            this.porteCuitCyo = cp.porteCuitCyo;
            this.porteCuitCyo2 = cp.porteCuitCyo2;
            this.porteFechaArr = cp.porteFechaArr;
            this.porteBruto = cp.porteBruto;
            this.porteKgsTara = cp.porteKgsTara;
            this.porteKgsNeto = cp.porteKgsNeto;
            this.porteMedio = cp.porteMedio;
            this.porteCodPuerto = cp.porteCodPuerto;
            this.portePatenteCamion = cp.portePatenteCamion;
            this.porteHoraArr = cp.porteHoraArr;
            this.porteDescarObs = cp.porteDescarObs;
            this.porteCalidad = cp.porteCalidad;
            this.porteVagon = cp.porteVagon;
            this.porteNroPlanta = cp.porteNroPlanta;
            this.porteTranspRazon = cp.porteTranspRazon;
            this.porteObservacion = cp.porteObservacion;
            this.porteCodTransp = cp.porteCodTransp;
            this.porteKilosMerma = cp.porteKilosMerma;
            this.portePreDesvio = cp.portePreDesvio;
            this.porteNroDesvio = cp.porteNroDesvio;
            this.porteRecibo = cp.porteRecibo;
            this.porteHoraSal = cp.porteHoraSal;
            this.porteFechaCp = cp.porteFechaCp;
            this.porteTipo = cp.porteTipo;
            this.porteNroPlaCyo1 = cp.porteNroPlaCyo1;
            this.porteNroPlaCyo2 = cp.porteNroPlaCyo2;
            this.porteNroAnt = cp.porteNroAnt;
            this.portePreAnt = cp.portePreAnt;
            this.porteVagonAnt = cp.porteVagonAnt;
            this.porteNetoAnt = cp.porteNetoAnt;
            this.porteCamara = cp.porteCamara;
            this.porteSolicAnalisis = cp.porteSolicAnalisis;
            this.porteDestinExis = cp.porteDestinExis;
            this.porteCc = cp.porteCc;
            this.porteAutorizado = cp.porteAutorizado;
            this.porteCosecha = cp.porteCosecha;
            this.porteImpExp = cp.porteImpExp;
            this.porteImpEnt = cp.porteImpEnt;
            this.porteTanda = cp.porteTanda;
            this.porteFirmaTransp = cp.porteFirmaTransp;
            this.porteCtg = cp.porteCtg;
            this.porteTitular = cp.porteTitular;
            this.porteInterm = cp.porteInterm;
            this.porteRemi = cp.porteRemi;
            this.destin = cp.destin;
            this.corre = cp.corre;
            this.cereal = cp.cereal;
            this.proce = cp.proce;
            this.entreCp = cp.entreCp;
            this.estadoPosi = cp.estadoPosi;
            this.entre = cp.entre;
            this.obsEstado = cp.obsEstado;
            this.codMerca = cp.codMerca;
            this.descripcionMercaderia = cp.descripcionMercaderia;
            this.tipoMercaderia = cp.tipoMercaderia;
            this.cosecha = cp.cosecha;
            this.tarifaXtonelada = cp.tarifaXtonelada;
            this.totalPlanta = cp.totalPlanta;
            this.turnoCalado = cp.turnoCalado;
            this.asigTurno = cp.asigTurno;
            this.hd = cp.hd;
            this.estadoPosiDes = cp.estadoPosiDes;
            this.ptoCodInterno = cp.ptoCodInterno;
            this.ptoCuit = cp.ptoCuit;
            this.ptoPostalOnnca = cp.ptoPostalOnnca;
            this.ptoRazon = cp.ptoRazon;
            this.ptoDomicilio = cp.ptoDomicilio;
            this.ptoDomiNro = cp.ptoDomiNro;
            this.toTelefono = cp.toTelefono;
            this.ptoFax = cp.ptoFax;
            this.ptoMail = cp.ptoMail;
            this.ptoTurno = cp.ptoTurno;
            this.ptoAdmin = cp.ptoAdmin;
            this.ptoPplantaAct = cp.ptoPplantaAct;
            this.ptoLocalOncca = cp.ptoLocalOncca;
            this.ptoControlTurno = cp.ptoControlTurno;
            this.ptoPlanta = cp.ptoPlanta;
            this.pAdicCuitChofer = cp.pAdicCuitChofer;
            this.pAdicRazonChofer = cp.pAdicRazonChofer;
            this.pAdicFechaVenc = cp.pAdicFechaVenc;
            this.pAdicBrutoProc = cp.pAdicBrutoProc;
            this.pAdicTaraProc = cp.pAdicTaraProc;
            this.pAdicPatenteAcopl = cp.pAdicPatenteAcopl;
            this.pAdicPNroCac = cp.pAdicPNroCac;
            this.cldNomenclatura = cp.cldNomenclatura;
            this.destinatariodeno = cp.destinatariodeno;
            this.cp = cp.cp;
            this.analiDet = cp.analiDet;
            this.obsAnalisis = cp.obsAnalisis;
            this.analisis = cp.analisis
                ? cp.analisis.map((a) => new Analisis(a))
                : [];
            this.autPuerto = cp.autPuerto;
            this.nomCorre2 = cp.nomCorre2;
            this.corre2 = cp.corre2;
            this.ruta = cp.ruta;
            this.obsPuerto = cp.obsPuerto;
            this.actComentario = cp.actComentario;
            this.rte1Cuit = cp.rte1Cuit;
            this.rte1Denominacion = cp.rte1Denominacion;
            this.rte2Cuit = cp.rte2Cuit;
            this.rte2Denominacion = cp.rte2Denominacion;
            this.pAdicNroCupo = cp.pAdicNroCupo;
            this.pAdicNroOp = cp.pAdicNroOp;
            this.sucursal = cp.sucursal;
            this.nroOrden = cp.nroOrden;
            this.tipoDocumento = cp.tipoDocumento;
        }
    }

    getNumeroCPE() {
        let sNroSucursal = "0000" + this.sucursal;
        let sNroOrden = "0000000" + this.nroOrden;
        let sNrocpE =
            sNroSucursal.substring(sNroSucursal.length - 5) +
            "-" +
            sNroOrden.substring(sNroOrden.length - 8);
        return sNrocpE;
    }

    getOtrosIntervinientes() {
        let inter1 = "";
        let inter2 = "";

        if (this.rte1Cuit) {
            inter1 = "(" + this.rte1Cuit + ") " + this.rte1Denominacion;
        }

        if (this.rte2Cuit) {
            inter2 = "(" + this.rte1Cuit + ") " + this.rte2Denominacion;
        }

        return inter1 + " - " + inter2;
    }

    getNroVagonOChasis() {
        if (this.porteVagon && this.porteVagon > 0) {
            return this.porteVagon;
        }
        return this.portePatenteCamion;
    }

    getNumeroCTG() {
        return this.portePrefijo + this.porteNro;
    }
}
