import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AppComponent } from './app.component';
import { MatTableModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, DateAdapter } from '@angular/material';

import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/authService';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactoService } from 'src/app/services/contactoService';
import { LocalStorageService } from 'src/app/services/localStorageService';
import { routing } from 'src/app/app.routes';
import { TokenInterceptor } from 'src/app/interceptors/TokenInterceptor';
import { UtilsService } from 'src/app/services/utilsService';
import { FilesService } from 'src/app/services/filesService';
import { AccionDialog } from './components/others/accionDialog/accionDialog';
import { HistoriaService } from './services/historiaService';
import { CartaPorteService } from 'src/app/services/cartaPorteService';
import { DefaultDialog } from 'src/app/components/others/defaultDialog/defaultDialog';
import { NotificacionesService } from './services/notificacionesService';
import { AppDateAdapter } from 'src/app/services/appDateAdapter';
import { WINDOW_PROVIDERS } from 'src/app/services/windowService';
import { NoticiasService } from './services/noticiasService';
import { ConfigService } from './services/configService';


@NgModule({
    declarations: [
        AppComponent,
        AccionDialog,
        DefaultDialog,
    ],
    entryComponents: [
        AccionDialog,
        DefaultDialog
    ],
    imports: [
        routing,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatSelectModule,
        MatDialogModule,
        MatIconModule,        
        MatButtonModule,
    ],
    providers: [
        AuthService,
        LocalStorageService,
        ContactoService,
        UtilsService,
        FilesService,
        HistoriaService,
        CartaPorteService,
        NotificacionesService,
        NoticiasService,
        WINDOW_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: LOCALE_ID, useValue: 'es' },
        ConfigService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
